import { sortPostCategoriesByLabel } from '@core/content';
import { mc, StyleProps } from '@styles';
import React, { FC } from 'react';

interface Props extends StyleProps {
  categories: string[];
  selected: string[];
  onItemClick?: (category: string) => void;
}

export const GuidesAndTipsLandingCategoryPills: FC<Props> = ({ className, categories, selected, onItemClick }) => {
  const renderCategoryPill = (category: string) => {
    const isSelected = selected.includes(category);

    const selectedClass = isSelected ? 'text-white bg-ocean-blue' : 'hover:text-white hover:bg-ocean-blue';

    return (
      <li key={category} className="py-1 lg:py-1 xl:py-0 px-1 lg:px-1 xl:px-2">
        <a
          onClick={() => onItemClick && onItemClick(category)}
          key={category}
          role="button"
          className={`${selectedClass} inline-block font-medium leading-4 py-2 xl:leading-6 xl:py-3 px-4 border border-ocean-blue rounded-full`}
        >
          {category}
        </a>
      </li>
    );
  };

  return (
    <ul className={mc('flex flex-row flex-wrap items-center', className)}>
      {sortPostCategoriesByLabel(categories).map(renderCategoryPill)}
    </ul>
  );
};

export type GuidesAndTipsLandingCategoryPillsProps = Props;
export default GuidesAndTipsLandingCategoryPills;
