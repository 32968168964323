import { BackBtn } from '@components/Buttons';
import { Divider } from '@components/Common';
import { authorHtmlParserOptions, PostBaseCard } from '@components/Post';
import { AuthorGuidesAndTips, PostBase } from '@core/content';
import { useLocation } from '@reach/router';
import { mc } from '@styles';
import { navigate } from 'gatsby';
import parseHtml from 'html-react-parser';
import { isEmpty, isNil, size } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  headerItems: PostBase[];
  items: PostBase[];
  category?: string;
}

export const GuidesAndTipsLandingHeader: FC<Props> = ({ items, headerItems: headerData, category }) => {
  const location = useLocation();
  const state = location.state as any;
  const [author, setAuthor] = useState<AuthorGuidesAndTips>();
  const [search, setSearch] = useState<string>();

  useEffect(() => {
    setAuthor(state?.author);
    setSearch(undefined); // just in case
    return () => {
      setAuthor(undefined);
    };
  }, [state?.author]);

  useEffect(() => {
    setSearch(state?.search);
    setAuthor(undefined); // just in case
  }, [state?.search]);

  // Apply only Active category when navigated within navbar, otherwise ignore any filtration and show based on createdAt
  const headerItems = headerData?.slice(0, 4) || [];

  const mainItem = headerItems[0];
  const secondaryHeaderItems = headerItems.slice(1);

  const renderDefaultHeader = () => {
    return (
      <>
        <div className="text-center mx-auto px-8 lg:px-0 w-full lg:max-w-5xl py-20">
          <h1 className="mt-0 text-accent-orange mb-4 inline-flex text-4xl lg:text-6xl">
            Guides & Tips {category ? `: ${category}` : ''}
          </h1>
          <p className="text-lg mx-auto font-medium text-ocean-blue max-w-3xl">
            If you suddenly find yourself caring for a relative, spouse, friend or child with special needs, we are here to help.
            We have pulled together some of the best advice, lessons learned, resources, and suggestions from our experts to help
            you get started.
          </p>
        </div>

        <Divider className="mb-10 lg:mb-20" />

        {!isEmpty(headerItems) && (
          <section className="hidden lg:block container mx-auto px-8 xl:px-16">
            <div className="flex flex-col lg:flex-row justify-between lg:-mx-4">
              <div className="w-full lg:w-6/12 lg:px-4 lg:-my-4 lg:mb-0">
                {mainItem && <PostBaseCard className={mc('mb-12 lg:mb-0')} item={mainItem} />}
              </div>
              <div className="w-full lg:w-6/12 lg:px-4 lg:-my-4">
                {secondaryHeaderItems.map((itm, index) => (
                  <PostBaseCard
                    key={itm.slug}
                    className={mc('mb-12 lg:mb-0', index !== 0 && 'mt-4 xl:mt-8')}
                    item={itm}
                    theme="green"
                    layout="horizontal"
                  />
                ))}
              </div>
            </div>

            {!isEmpty(headerItems) && <Divider className={mc('hidden md:block', 'my-16')} />}
          </section>
        )}
      </>
    );
  };

  const renderByAuthorHeader = () => (
    <div className="my-16 container mx-auto px-16">
      <BackBtn onClick={() => navigate(location.pathname)} />
      <h3 className="mb-0 mt-2">{author?.name}</h3>
      <p className="text-lg mt-2">{author?.about ? parseHtml(author.about, authorHtmlParserOptions) : null}</p>
    </div>
  );

  const renderSearchHeader = () => (
    <div className="my-16 container mx-auto px-16">
      <BackBtn title="Back to Guides & Tips" onClick={() => navigate(location.pathname)} />
      <h3 className="mb-0 mt-2">Showing results for "{search}"</h3>
      <p className="text-lg font-medium">{size(items)} results</p>
    </div>
  );

  // In case author supplied, show some kind of "page"
  // Render about and filter all items based on author picked
  if (!isNil(author)) return renderByAuthorHeader();

  // In case we are dealing with search header
  if (!isNil(search)) return renderSearchHeader();

  // By default render card header
  return renderDefaultHeader();
};

export default GuidesAndTipsLandingHeader;
