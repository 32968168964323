import { PostSortType } from '@components/Forms';
import { PostBase } from '@core/content';
import { compact, flatten, size, uniq } from 'lodash';

export const ALL_CATEGORY = 'All';

export const applyCategoryFilter = (data: PostBase[], categoryIds: string[], type?: PostSortType, query?: string) => {
  const hasAll = categoryIds.indexOf(ALL_CATEGORY) > -1;

  if (hasAll) {
    return applySort(data, type).filter(e => filterByQuery(e, query || ''));
  }

  return applySort(
    data
      .filter(e => size((e.categories || []).filter(c => categoryIds.includes(c))) > 0)
      .filter(e => filterByQuery(e, query || '')),
    type,
  );
};

const filterByQuery = (e: PostBase, query: string) => e.title.toLowerCase().includes(query.toLowerCase());

export const applySort = (data: PostBase[], type?: PostSortType) => {
  if (!type) return data;

  switch (type) {
    case 'RECENT_ASC': {
      return data.slice().sort((a, b) => sortPostsDatesDescendingFn(b, a));
    }
    case 'RECENT_DESC': {
      return data.slice().sort((a, b) => sortPostsDatesDescendingFn(a, b));
    }
    case 'ALPHABET_DESC':
      return data.slice().sort((a, b) => sortPostsAlphabeticallyFn(b, a));
    case 'ALPHABET_ASC':
      return data.slice().sort((a, b) => sortPostsAlphabeticallyFn(a, b));

    default:
      return data;
  }
};

export const sortPostsDatesDescendingFn = (a: PostBase, b: PostBase) => {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
};

const sortPostsAlphabeticallyFn = (a: PostBase, b: PostBase) => {
  return a.title.localeCompare(b.title, 'en', {
    sensitivity: 'base',
  });
};

export const getGuidesAndTipsCategories = (guidesAndTips: PostBase[], additionalCategories: string[] = []) => {
  return compact(uniq(flatten([...additionalCategories, ...guidesAndTips.map(e => e.categories)])));
};
