import { Divider, SeoHeader } from '@components/Common';
import { PostSortType, SortByDropdown } from '@components/Forms';
import { GuidesAndTipsEmptyState, GuidesAndTipsFooter } from '@components/GuidesAndTips';
import { Footer, NavigationHeader } from '@components/Navigation';
import { PostBaseCard } from '@components/Post';
import { GuidesAndTipsResponse, PostBase } from '@core/content';
import { mc } from '@styles';
import { PageRendererProps } from 'gatsby';
import { isEmpty, size } from 'lodash';
import React, { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useMediaQuery, useUpdateEffect } from 'usehooks-ts';

import GuidesAndTipsLandingCategoryPills from './components/CategoryPills';
import GuidesAndTipsHomeHeader from './components/Header';
import { ALL_CATEGORY, applyCategoryFilter, applySort, getGuidesAndTipsCategories } from './utils';

interface Props extends PageRendererProps {
  data: GuidesAndTipsResponse;
  pageContext: PageContext;
}

interface PageContext {
  slug: string;
  category?: string;
  categories: string[];
  posts: PostBase[];
}

const RESULT_SHOW_LIMIT = 12;

const GuidesLandingPage: FC<Props> = ({ pageContext }) => {
  const { posts: incomingPosts, category: currentCategory } = pageContext;
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const response = applySort(incomingPosts, 'RECENT_DESC');

  // Store data just in order to be able to apply some filtration over it
  const [allItems, setAllItems] = useState<PostBase[]>(response);
  // Show only first 6 items and then go over pagination
  const [feedItems, setFeedItems] = useState<PostBase[]>(allItems.slice(0, RESULT_SHOW_LIMIT));

  // Categories

  const availableCategories = getGuidesAndTipsCategories(response, [ALL_CATEGORY]);
  const [selectedCategories, setCategories] = useState<string[]>([ALL_CATEGORY]);

  // Sort

  const [selectedSortType, setSortType] = useState<PostSortType>('RECENT_DESC');

  useUpdateEffect(() => {
    const newEdges = applyCategoryFilter(response, selectedCategories, selectedSortType);
    setAllItems(newEdges);
    setFeedItems(newEdges.slice(0, RESULT_SHOW_LIMIT));
  }, [JSON.stringify(selectedCategories), selectedSortType]);

  const handleLoadMore = () => {
    setFeedItems([...feedItems, ...allItems.slice(size(feedItems), size(feedItems) + RESULT_SHOW_LIMIT)]);
  };

  const handleCategoryClick = (category: string) => {
    const exists = selectedCategories.find(c => c === category);
    const allClicked = category === ALL_CATEGORY;

    if (allClicked) {
      setCategories([ALL_CATEGORY]);
      return;
    }

    if (exists) {
      const categories = selectedCategories.filter(c => c !== category).filter(c => c !== ALL_CATEGORY); // Remove all from the list
      setCategories(isEmpty(categories) ? [ALL_CATEGORY] : categories);
      return;
    }

    setCategories(
      [...selectedCategories, category].filter(c => c !== ALL_CATEGORY), // Remove all from the list
    );
  };

  const handleSortChange = (sortType: PostSortType) => {
    if (selectedSortType === sortType) return setSortType('RECENT_DESC');
    setSortType(sortType);
  };

  // Apply only Active category when navigated within navbar, otherwise ignore any filtration and show based on createdAt
  const headerItems = applyCategoryFilter(response.slice(), [ALL_CATEGORY], selectedSortType);

  const headerItemIds = headerItems?.slice(0, 4).map(e => e.slug);

  const filteredFeedItems = feedItems.filter(e => {
    return !headerItemIds.includes(e.slug);
  });

  // Show all items when mobile but only filtered when desktop
  const displayedFeedItems = isMobile ? feedItems : filteredFeedItems;

  const itemsCountWithSubtractedHeaderCount = Math.max(size(allItems) - size(headerItemIds), 0);

  return (
    <>
      <NavigationHeader />

      <GuidesAndTipsHomeHeader category={currentCategory} items={allItems} headerItems={headerItems} />

      {/* Filter */}
      <section className={mc('container', 'mx-auto', 'px-8 xl:px-16 pb-10 lg:pb-0')}>
        <div className="flex flex-col md:flex-row items-start xl:items-center justify-between">
          {!currentCategory && (
            <GuidesAndTipsLandingCategoryPills
              className="-my-1 lg:-my-0 -mx-1 lg:-mx-2 mb-3 md:mb-0"
              categories={availableCategories}
              selected={selectedCategories}
              onItemClick={handleCategoryClick}
            />
          )}
          {!isEmpty(displayedFeedItems) && <SortByDropdown onChange={handleSortChange} selected={selectedSortType} />}
        </div>
      </section>
      {/* Filter */}

      <section className={mc('container', 'mx-auto', 'px-8 xl:px-16')}>
        {!!size(displayedFeedItems) && (
          <>
            <InfiniteScroll
              className="flex flex-col lg:flex-row flex-wrap lg:-mx-4 lg:py-4"
              pageStart={0}
              loadMore={handleLoadMore}
              hasMore={size(displayedFeedItems) < itemsCountWithSubtractedHeaderCount}
              loader={
                <div className="w-full lg:w-1/3 lg:px-4 py-4 flex flex-row justify-center" key={`loader-main`}>
                  Loading...
                </div>
              }
            >
              {displayedFeedItems.map(e => (
                <PostBaseCard
                  key={`${e.slug}-main`}
                  className={mc('w-full lg:w-1/3', 'mb-12 lg:mb-0 lg:px-4 lg:py-4')}
                  item={e}
                />
              ))}
            </InfiniteScroll>
          </>
        )}

        {isEmpty(feedItems) && <GuidesAndTipsEmptyState className="pb-20" />}
      </section>

      {!isEmpty(displayedFeedItems) && (
        <div className="container mx-auto px-6 md:px-20">
          <Divider />
        </div>
      )}

      <GuidesAndTipsFooter type="get-started-welcome" />

      <Footer privacyBannerColor="seafoam" />
    </>
  );
};

export const Head: FC<{ pageContext: PageContext }> = ({ pageContext }) => (
  <SeoHeader title={pageContext.category ? `Guides & Tips: ${pageContext.category}` : 'Guides & Tips'} />
);

export default GuidesLandingPage;
